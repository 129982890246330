
  import {
    PlusOutlined,
    QuestionCircleOutlined
  } from '@ant-design/icons-vue';
  import axios from 'axios';
  import {
    ref,
    onActivated
  } from 'vue';
  import {
    message
  } from "ant-design-vue"

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = error => reject(error);
    });
  }
  export default {
    name: 'FormBasic',
    components: {
      PlusOutlined,
      QuestionCircleOutlined
    },
    data() {
      return {
        // 加载状态
        loading: false,
        // 表单数据
        form: {
          currency: 'CNY',
          allow_customer_shipper: '1'
        },
        // 表单验证规则
        rules: {
          company_name: [{
            required: true,
            message: this.$t('必填'),
            trigger: 'blur'
          }]
        },
        // 活动的服务器
        servers: [],
        // 时区列表
        timezones: [],
        /* 上传logo */
        logoList: [],
        /* 预览图片 */
        previewVisible: false,
        /* 图片源 */
        previewImage: null,
        /* 标签页激活页 */
        activeKey: "1"
      };
    },
    mounted() {
      this.load_data();
    },
    methods: {
      /* 移除图片 */
      handleRemove() {
        this.logoList = [];
        this.form.logo = ``;
      },
      /* 预览功能 */
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }

        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      /* 上传缩略图 */
      handleUploadShort(resFile) {
        this.handleUpload(resFile, 'logoList');
      },
      /* 上传长图 */
      handleUploadLong(resFile) {
        this.handleUpload(resFile, 'logoLongList');
      },
      // 上传附件
      handleUpload(resFile, key) {
        this.loading = true;
        const formData = new FormData();
        formData.append("file", resFile.file);

        this.imgFile = resFile.file;
        this.$http
          .post("/admin/deposit/upload_attachment", formData)
          .then((response) => {
            if (response.status == 200 && response.data.code == 0) {
              const res = response.data;
              this.form[key.toString().replace("List", "")] = res.data;
              this.$message.success(this.$t("操作成功"));
              resFile.onSuccess(res.data, resFile.file);
            }
          })
          .catch(() => {
            resFile.onError();
            this.$message.error(this.$t("操作失败"));
            this[key] = [];
          })
          .finally(() => {
            this.loading = false;
          });
      },
      /**
       * 加载数据
       */
      load_data() {
        Promise.all([
          this.$http.get(`/admin/system_config/list/basic`),
          this.$http.get(`/admin/system_server/list`),
          this.$http.get(`/admin/system_config/timezones`)
        ]).then(res => {
          if (res[0].data.code === 0) {
            var data = Object.assign({}, res[0].data.data);
            data.allow_edit_balance = data.allow_edit_balance === '1';
            data.order_ref = data.order_ref === '1';
            data.shipper = data.shipper === '1';
            data.order_freightclass = data.order_freightclass === '1';
            data.order_realtime_label = data.order_realtime_label === '1';
            data.order_void_double_check = data.order_void_double_check === '1';
            data.allow_customer_shipper = data.allow_customer_shipper === '1';
            data.allow_customs_data = data.allow_customs_data === '1';
            data.ups_auto_download_invoice = data.ups_auto_download_invoice === '1';
            data.fedex_auto_download_invoice = data.fedex_auto_download_invoice === '1';
            data.download_auto_import_attachment = data.download_auto_import_attachment === '1';
            data.allow_order_delete_all = data.allow_order_delete_all === '1';
            data.show_cost_module = data.show_cost_module === '1';
            data.again_pay_service = data.again_pay_service === '1';
            this.logoList = data.logo ? [{
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: process.env.VUE_APP_STS_FILE_URL + data.logo,
            }] : [];
            this.form = data;
          } else {
            this.$message.error(res[0].data.msg);
          }

          if (res[1].data.code === 0) {
            this.servers = res[1].data.data;
          }

          if (res[2].data.code === 0) {
            this.timezones = res[2].data.data;
          }
        }).catch(e => {
          this.$message.error(e.message);
        });
      },
      /**
       * 提交保存
       */
      submit() {
        this.$refs.form.validate().then(() => {
          this.loading = true;

          var body = Object.assign({}, this.form);

          body.allow_edit_balance = body.allow_edit_balance ? '1' : '0';
          body.order_realtime_label = body.order_realtime_label ? '1' : '0';
          body.order_void_double_check = body.order_void_double_check ? '1' : '0';
          body.allow_customer_shipper = body.allow_customer_shipper ? '1' : '0';
          body.order_ref = body.order_ref ? '1' : '0';
          body.shipper = body.shipper ? '1' : '0';
          body.order_freightclass = body.order_freightclass ? '1' : '0';
          body.allow_customs_data = body.allow_customs_data ? '1' : '0';
          body.ups_auto_download_invoice = body.ups_auto_download_invoice ? '1' : '0';
          body.fedex_auto_download_invoice = body.fedex_auto_download_invoice ? '1' : '0';
          body.download_auto_import_attachment = body.download_auto_import_attachment ? '1' : '0';
          body.allow_order_delete_all = body.allow_order_delete_all ? '1' : '0';
          body.show_cost_module = body.show_cost_module ? '1' : '0';
          body.again_pay_service = body.again_pay_service ? '1' : '0';

          this.$http.post(`/admin/system_config/update/basic`, body).then(res => {
            this.loading = false;

            if (res.data.code === 0) {
              this.$store.dispatch('system/getBasicConfig');
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        }).catch(() => {});
      }
    },
    setup() {
      const CURRENCY = ref([]);
      const getCurrencyList = () => {
        axios.get('/admin/currency/list', {
          params: {
            page: 1,
            limit: 9999,
            sort: "sort",
            order: "asc"
          }
        }).then(res => {
          if (res.data.code == 0) {
            CURRENCY.value = res.data.data;
          }
        }).catch(e => {
          message.error(e.message)
        });
      }

      onActivated(() => {
        getCurrencyList();
      })

      return {
        CURRENCY
      }
    }
  }
